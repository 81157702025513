import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import { AvForm, AvInput, AvGroup, AvField, AvFeedback } from "availity-reactstrap-validation"
import { Container, Row, Col, Button, Label, FormText, CustomInput, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap"
import styles from "../pages/css/index.module.css"

class ReportAViolation extends React.Component { 
	constructor(props) {
		super(props);
		
		this.fileInput = React.createRef();
		this.handleValidSubmit = this.handleValidSubmit.bind(this);
		this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
		this.state = {
			modal: false,
			file: null
		};
		this.toggle = this.toggle.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		})
	}

	onChange(e) {
		this.setState({
			file: e.target.files[0],
		})

		if (e.target.files[0].size > 10000000) {
			alert('Please upload a file smaller than 10MB.')
		} else {
			this.fileInput.current.nextSibling.innerHTML = this.fileInput.current.files[0].name;
		}
	}

	handleValidSubmit(event, values) {
		this.setState({values});
		values.attachment = this.fileInput.current.files[0];
		let data = new FormData();
		data.append('store', values.store);
		data.append('violationType', values.violationType);
		data.append('violationOther', values.violationOther);
		data.append('description', values.description);
		data.append('attachment', values.attachment);
		data.append('violationDate', values.violationDate);
		data.append('discovered',values.discovered);
		data.append('discoveredOther',values.discoveredOther);
		data.append('firstName',values.firstName);
		data.append('lastName',values.lastName);
		data.append('company',values.company);
		data.append('job',values.job);
		data.append('phone',values.phone);
		data.append('email',values.email);
		data.append('form', values.formType);

		// fetch('https://apis.progressmfg.com/', {
		fetch('http://localhost:5000/', {
			method: 'POST',  
			body: data
		}).then((res) => {
			this.toggle();
		}).then(() => {
			this.fileInput.current.nextSibling.innerHTML = 'Choose file';
			this.form && this.form.reset();
		}).catch(err => console.log(err));
	}

	handleInvalidSubmit(event, errors, values) {
		this.setState({event, errors, values});
	}

	render() {
		const report = this.props.data.craft.content[0]
		return (
			<div>
				<Header/>
				<div className={styles.heroInterior}>
					<Container>
						<Row>
							<Col md={{ offset: 2, order: 2, size: 8 }} xs="12">
								<div className={styles.faqContent}>
									<h1>{report.mainContentHeadline}</h1>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={{ offset: 2, order: 2, size: 8 }} xs="12">
								<div className={styles.faqCard}>
									<AvForm className={styles.contactForm} onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit} name="reportViolation" id="reportViolation" ref={c => (this.form = c)} encType="multipart/form-data">
										<div dangerouslySetInnerHTML={{ __html: report.mainContent }}></div>
										<AvGroup className={styles.wholeInput}>
											<Label for="store">Store/Seller Name <span className={styles.requiredMark}>*</span>:</Label>
											<AvInput type="text" name="store" id="store" required />
											<AvFeedback>Please enter a seller/store name.</AvFeedback>
										</AvGroup>                                
										<AvGroup className={styles.halfInput}>
											<Label for="violationType">Type of Violation <span className={styles.requiredMark}>*</span>:</Label>
											<AvField type="select" name="violationType" id="violationType" validate={{ required: {value: true, errorMessage: 'Please select a violation type.'} }}>
												<option value="" defaultValue disabled>Choose A Violation</option>
												<option value="Pricing">Pricing</option>
												<option value="Auction Site">Auction Site</option>
												<option value="Unauthorized Reseller">Unauthorized Reseller</option>
												<option value="Sale to Violator">Sale to Violator</option>
												<option value="Other">Other</option>
											</AvField>
										</AvGroup>
										<AvGroup className={styles.halfInput}>
											<Label for="violationOther">If Other:</Label>
											<AvInput type="text" name="violationOther" id="violationOther"/>
										</AvGroup>                             
										<AvGroup className={styles.wholeInput}>
											<Label for="description">Description of Violation:</Label>
											<AvInput type="textarea" name="description" id="description" />
										</AvGroup>
										<AvGroup className={styles.halfInput}>
											<Label for="dateDiscovered">Date Discovered:</Label>
											<AvField type="text" name="dateDiscovered" id="dateDiscovered" placeholder="MM/DD/YYYY"  title="Use MM/DD/YYYY"/>
										</AvGroup>
										<AvGroup className={styles.halfInput}>
											<Label for="attachment">Violation Screenshot:</Label>
											<CustomInput type="file" id="attachment" name="attachment" onChange={this.onChange} innerRef={this.fileInput}/>
											<FormText color="muted">Upload a screenshot of the voilation from the web. Must be less than 10MB.</FormText>
										</AvGroup>
										<AvGroup className={styles.halfInput}>
											<Label for="discovered">Where Discovered <span className={styles.requiredMark}>*</span>:</Label>
											<AvField type="select" name="discovered" id="discovered" validate={{ required: {value: true, errorMessage: 'Please select where violation was discovered.'} }}>
												<option value="" defaultValue disabled>Choose Where Violation Was Discovered</option>
												<option value="Advertisement">Advertisement</option>
												<option value="Catalog">Catalog</option>
												<option value="Flyer">Flyer</option>
												<option value="In Store">In Store</option>
												<option value="Receipt">Receipt</option>
												<option value="Auction Site">Auction Site</option>
												<option value="Website">Website</option>
												<option value="Other">Other</option>
											</AvField>
										</AvGroup>
										<AvGroup className={styles.halfInput}>
											<Label for="discoveredOther">If Other:</Label>
											<AvInput type="text" name="discoveredOther" id="discoveredOther"/>
										</AvGroup>    
										<AvGroup className={styles.halfInput}>
											<Label for="firstName">First Name <span className={styles.requiredMark}>*</span>:</Label>
											<AvInput type="text" name="firstName" id="firstName" required/>
											<AvFeedback>Please enter your first name.</AvFeedback>
										</AvGroup>                            
										<AvGroup className={styles.halfInput}>
											<Label for="lastName">Last Name <span className={styles.requiredMark}>*</span>:</Label>
											<AvInput type="text" name="lastName" id="lastName" required/>
											<AvFeedback>Please enter your last name.</AvFeedback>
										</AvGroup>                            
										<AvGroup className={styles.halfInput}>
											<Label for="company">Company:</Label>
											<AvInput type="text" name="company" id="company" />
										</AvGroup>              
										<AvGroup className={styles.halfInput}>
											<Label for="job">Job Title:</Label>
											<AvInput type="text" name="job" id="job" />
										</AvGroup>                            
										<AvGroup className={styles.halfInput}>
											<Label for="phone">Phone Number:</Label>
											<AvInput type="telephone" name="phone" id="phone" />
										</AvGroup>                            
										<AvGroup className={styles.halfInput}>
											<Label for="email">Email <span className={styles.requiredMark}>*</span>:</Label>
											<AvInput type="email" name="email" id="email" required/>
											<AvFeedback>Please enter a valid email address.</AvFeedback>
										</AvGroup>  
										<AvGroup className={styles.wholeInput}>
											<AvInput type="hidden" name="formType" id="formType" required value="report"/>
										</AvGroup>                                     
										<Button className={styles.formSubmitButton}>Report This Violation</Button>
									</AvForm>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<Footer/>
				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
					<ModalHeader className={styles.modalHeadline} toggle={this.toggle}>{report.thankYouHeadline}</ModalHeader>
					<ModalBody dangerouslySetInnerHTML={{ __html: report.thankYouContent }}></ModalBody>
					<ModalFooter>
						<Button className={styles.formSubmitButton} onClick={this.toggle}>Close</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}

export const query = graphql`
  query ReportPage {
    craft {
      content: entries(siteId: "4", slug: "report-a-violation", status: "disabled") {
        id
        ... on craft_reportAViolation_reportAViolation_Entry {
          id
          mainContentHeadline
          mainContent 
          thankYouHeadline
          thankYouContent
        }
      }
    }
  }
`;

export default ReportAViolation